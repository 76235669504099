import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getFirebaseApp } from "helpers/firebase_helper";
import { getAuth } from "firebase/auth";
import { Container, Row, Spinner } from "reactstrap";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from "react-redux";
import { getProfile, loadUser } from "store/actions";
import { analytics } from "helpers/analytics_helper";
import { createSelector } from "reselect";



const AuthMiddleware = (props: any) => {

  const auth = getAuth(getFirebaseApp());
  const [user, loading, error] = useAuthState(auth);

  const dispatch = useDispatch();
  
  const {
    profile,
  } = useSelector(createSelector((state) => state.profile, 
        (profile: any) => ({profile: profile.profile})));

  useEffect(() => {
    if (user) {
      dispatch(getProfile());
    }
  }, [user]);

  useEffect(() => {
    if (user && profile) {
      dispatch(loadUser({user, profile}));
      analytics.identify(user.uid, {
        name: user.displayName,
        email: user.email
      });
    }
  }, [profile]);
  

  if (loading) {
    return <Container>
      <Row className="justify-content-center position-absolute start-50 top-30">
        <Spinner/>
      </Row>
    </Container>
  }

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AuthMiddleware;
