import Avatar from "components/Common/Avatar";
import ChatOnlineStatus from "./ChatOnlineStatus";

const ChatProfileImage = ({member, index, size}: {member: any, index: number, size: string}) => {

    const getProfileUrl = (profileUrl) => {
        if (profileUrl?.startsWith("/")) {
            return process.env.PUBLIC_URL + profileUrl;
        }
        // return console.log("profileUrl", profileUrl);
        return profileUrl;
    }
    return (
        <>
            {member && <span className={`position-relative ${index>0 && "ms-n4"} z-${index+2}`}>
                <Avatar size={size == "xs" ? "40" : "60"} name={member.name} round={true} src={getProfileUrl(member.profileUrl)} style={{maxWidth: "none"}} 
                                    className="img-thumbnail align-self-center p-0"/>
                <ChatOnlineStatus member={member} isBottomRight={true} />
            </span>}
        </>
    );
}

export default ChatProfileImage;