import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  loading: true,
  user: null, 
  isUserLogout: true
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
    case LOAD_USER:
    case EDIT_USER:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserLogout: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: {...action.payload.user, claims: action.payload.profile.claims},
        loading: false,
      };
    case LOGOUT_USER:
      return { ...state };
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, isUserLogout: true};
    case API_ERROR:
      return { ...state, error: action.payload, loading: false, isUserLogout: false, };
    case EDIT_USER_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case EDIT_USER_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default: 
      return state;
  }
}

export default login
