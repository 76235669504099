import Avatar from "components/Common/Avatar";
import { Link } from "react-router-dom";
import ChatProfileImage from "./ChatProfileImage";
import { MouseEventHandler, useEffect, useState } from "react";
import ChatLastMessage from "./ChatLastMessage";
import moment from "moment";



const ChatNotifications = ({channels, currentUser, onClick}: {channels: any[], currentUser: any, onClick: MouseEventHandler}) => {

    const [channelsUnread, setChannelsUnread] = useState([]);


    useEffect(() => {
        setChannelsUnread(channels.filter(c => c.newCount > 0 && c.lastMessage));
    }, [channels]);


    const getSender = (channel) => {

        let sender = channel.contacts?.find(({uid}) => uid == channel.lastMessage?.senderId);
        if (!sender) {
            console.log("No sender!", channel, channel.lastMessage)
        } 
        return sender;
    }

    const getTime = (channel) => {
        return moment(channel.lastMessage?.sentAt).fromNow(); 
    }

    return <>
        {channelsUnread.map(channel => 
            <Link to={`/chat/${channel.id}`} onClick={onClick} className="text-reset notification-item" key={`not-${channel.id}`}>
            <div className="row ms-1 py-2">
              <div className="col-2">
                <ChatProfileImage size="xs" member={getSender(channel)} index={0}/>
              </div>
              <div className="col me-2" style={{width: "50%"}}>
                <h6 className="mt-0 mb-1">{channel.name}</h6>
                <div className="font-size-12 text-muted">
                  {/* <p className="mb-0">
                    
                  </p> */}
                  <p className="mb-0 text-truncate">
                    <ChatLastMessage channel={channel} message={channel.lastMessage} currentUser={currentUser}/>
                  </p>
                  <p className="chat-time mb-0 fw-lighter">
                    {getTime(channel)}
                  </p>
                </div>
              </div>
            </div>
          </Link>

        )}
    </>


}

export default ChatNotifications;